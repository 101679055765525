import React from "react";

export default function PageIllustration({ multiple = false }) {
  return (
    <div className="relative">
      {!multiple && (  // Show all images when `multiple` is false
        <>
          <img
            className="max-w-none absolute top-0 right-0"
            src="/images/page-illustration.svg"
            width={846}
            height={594}
            alt="Page illustration"
          />
          <img
            className="max-w-none absolute top-[20%] left-[0%]"
            src="/images/blurred-shape-gray.svg"
            width={760}
            height={668}
            alt="Blurred shape"
          />
          <img
            className="max-w-none absolute top-[20%] left-[0%]"
            src="/images/blurred-shape.svg"
            width={760}
            height={668}
            alt="Blurred shape"
          />
        </>
      )}

      {multiple && (  // Show only blurred shapes when `multiple` is true
        <>
          <img
            className="max-w-none absolute top-[20%] right-[0%]"
            src="/images/blurred-shape-gray.svg"
            width={760}
            height={668}
            alt="Blurred shape"
          />
          <img
            className="max-w-none absolute top-[40%] left-[0%]"
            src="/images/blurred-shape.svg"
            width={700}
            height={400}
            alt="Blurred shape"
          />
        </>
      )}
    </div>
  );
}
