import React, { useEffect, useState } from "react";

const Modal = ({ isOpen, onClose, title, content, image, video }) => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }, [isOpen]);

  if (!isOpen) return null;

  const handleModalClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div
      className={`fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-70 transition-opacity duration-300 ${
        showModal ? "opacity-100" : "opacity-0"
      }`}
      onClick={onClose}
    >
      <div
        className={`relative bg-gray-950 rounded-xl overflow-hidden p-4 shadow-lg w-[370px] sm:w-full max-w-5xl border border-indigo-300 transform transition-transform duration-300 ${
          showModal ? "scale-100" : "scale-95"
        }`}
        style={{ maxHeight: "95vh", overflowY: "auto" }}
        onClick={handleModalClick}
      >
        <button
          className="absolute top-2 right-4 text-gray-300 hover:text-white text-lg font-bold"
          onClick={onClose}
          aria-label="Close"
        >
          &times;
        </button>
        <div className="flex justify-center mt-4">
          <span className="btn-sm relative rounded-full bg-gray-800/40 px-2.5 py-3 text-xs font-normal hover:bg-gray-800/60 ">
            <span className="bg-gradient-to-r from-indigo-500 to-indigo-200 bg-clip-text text-transparent text-[20px] py-2">
              {title}
            </span>
          </span>
        </div>

        <div className="flex flex-col md:flex-row">
        <div className="flex-shrink-0 md:w-1/2 p-4 sm:p-8 overflow-hidden">
  {video ? (
    <video
      className="w-full max-w-[500px] rounded-2xl"
      loop
      autoPlay
      muted
      alt="Features"
      style={{ height: "auto" }}
    >
      <source src={video} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  ) : (
    <img
      src={image}
      alt={title}
      width={350}
      className="rounded-md w-none h-auto"
    />
  )}
</div>


          <div className="flex-1 p-2 md:p-2 flex flex-col items-center justify-center text-center">
            <p className="text-gray-300 text-xl leading-relaxed justify-evenly text-left ">
              {content}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
