import React, { useEffect } from 'react';
import Slider from 'react-slick';
import { FaChartLine, FaShareAlt, FaVideo, FaMousePointer, FaThumbsUp, FaBullseye, FaVolumeMute } from 'react-icons/fa';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AOS from 'aos';
import 'aos/dist/aos.css';

const stats = [
    { heading: "Conversion Boost", text: "93% of businesses using personalized video see an increase in conversion rates.", image: "path/to/image1.jpg", icon: <FaChartLine size={30} className="text-indigo-500" /> },
    { heading: "Highly Shareable", text: "Short form videos are shared 1200% more than text and images combined.", image: "path/to/image2.jpg", icon: <FaShareAlt size={30} className="text-indigo-500" /> },
    { heading: "Video Influence", text: "65% of consumers purchased a product after watching a short-form video ad.", image: "path/to/image3.jpg", icon: <FaVideo size={30} className="text-indigo-500" /> },
    { heading: "Higher CTR", text: "Short form video ads have a 23% higher CTR compared to display.", image: "path/to/image4.jpg", icon: <FaMousePointer size={30} className="text-indigo-500" /> },
    { heading: "Preferred Content", text: "74% of consumers prefer short-form video for brand marketing.", image: "path/to/image5.jpg", icon: <FaThumbsUp size={30} className="text-indigo-500" /> },
    { heading: "Better Conversions", text: "Short form video ads have a 34% higher conversion rate vs traditional video ads.", image: "path/to/image6.jpg", icon: <FaBullseye size={30} className="text-indigo-500" /> },
    { heading: "Sound Optional", text: "85% of Facebook users watch videos with the sound off.", image: "path/to/image7.jpg", icon: <FaVolumeMute size={30} className="text-indigo-500" /> }
];

const StatsCarousel = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: false,  
        centerPadding: '400px',
    };

    useEffect(() => {
        AOS.init({
            duration: 2000, 
            easing: 'ease-out', 
            once: true,
        });
    }, []);

    return (
        <section className="flex items-center justify-center h-[400px] bg-tranparent">
            <div className="mx-auto max-w-md sm:max-w-4xl px-4 sm:px-0">
                <div className="py-2 md:py-0 text-center">
                    <div className="inline-flex md:pb-2 pb-2 items-center gap-3 pb-3 before:h-px before:w-8 before:bg-gradient-to-r before:from-transparent before:to-indigo-200/50 after:h-px after:w-8 after:bg-gradient-to-l after:from-transparent after:to-indigo-200/50">
                        <span className="inline-flex bg-gradient-to-r from-indigo-500 to-indigo-200 bg-clip-text text-transparent text-2xl">
                            Key Metrics
                        </span>
                    </div>

                    <div className="relative flex items-center justify-center py-6 px-6 ">
                        <Slider {...settings} className="w-full flex justify-center items-center">
                            {stats.map((stat, index) => (
                                <div 
                                    key={index} 
                                    className="flex flex-col items-center justify-center text-center my-8 px-2 rounded-xl border border-indigo-300"
                                    data-aos="fade-down" 
                                >
                                    <div className="flex items-center justify-center py-4">
                                        <div className="text-4xl">
                                            {stat.icon}
                                        </div>
                                    </div>

                                    <p className="text-xl font-semibold text-gray-250 pb-6">
                                        {stat.text}
                                    </p>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </div>
            <style>
                {`
                    .scrollbar-hide::-webkit-scrollbar {
                        display: none;
                    }
                    .slick-dots li button:before {
                        color: white !important;
                    }
                `}
            </style>
        </section>
    );
};

export default StatsCarousel;
