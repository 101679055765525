import React from "react";
import { motion } from "framer-motion";
import ModalVideo from "./modal-video";

export default function HeroHome() {
  return (
    <section>
      <div className="relative mx-auto max-w-6xl px-4 sm:px-6 mt-40 sm:mt-0">
      <div className="flex h-[70vh] sm:h-[100vh] items-end justify-start py-10 md:py-20">
      <div className="grid gap-8 md:grid-cols-2 items-end m-2">
            <motion.div
              className="pb-6 text-left md:pb-10 md:-mt-6"
              initial="hidden"
              animate="visible"
              variants={{
                hidden: { opacity: 0, y: 20 },
                visible: { opacity: 1, y: 0 },
              }}
              transition={{ duration: 0.7, ease: "easeOut" }}
            >
              <h1
                className="animate-[gradient_6s_linear_infinite] bg-[linear-gradient(to_right,theme(colors.gray.200),theme(colors.indigo.200),theme(colors.gray.50),theme(colors.indigo.300),theme(colors.gray.200))] bg-[length:200%_auto] bg-clip-text pb-5 text-5xl font-bold text-transparent md:text-7xl"
              >
                HyGaar
              </h1>

              <div className="mx-auto max-w-5xl">
                <motion.p
                  className="mb-8 text-3xl text-indigo-200/65"
                  data-aos="fade-up"
                  data-aos-delay={200}
                  initial="hidden"
                  animate="visible"
                  variants={{
                    hidden: { opacity: 0, y: 20 },
                    visible: { opacity: 1, y: 0 },
                  }}
                  transition={{ duration: 0.9, ease: "easeOut", delay: 0.4 }}
                >
                  Our Platform. Your Story. <br />
                  <span className="text-xl font-bold text-indigo-100">In every frame.</span>
                </motion.p>
              </div>
            </motion.div>

            <motion.div
              className="flex justify-start md:justify-end"
              initial="hidden"
              animate="visible"
              variants={{
                hidden: { opacity: 0, y: 20 },
                visible: { opacity: 1, y: 0 },
              }}
              transition={{ duration: 1, ease: "easeOut", delay: 0.8 }}
            >
              <div className="relative  max-w-lg">
                <ModalVideo
                  thumb="/images/Untitleddesign1.jpg"
                  thumbWidth={1104}
                  thumbHeight={576}
                  thumbAlt="Modal video thumbnail"
                  video="https://track.viwomail.com/videoemail/LP/055096500173148826367346a07868e8/raw/embed.html?controls=1&autoplay=1&muted=1&loop=0"
                  videoWidth={700}
                  videoHeight={370}
                />
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    </section>
  );
}
