import React from "react";

export default function Features() {
  return (
    <section className="relative">
      <div
        className="pointer-events-none absolute top-0 -z-10 -mt-20 "
        aria-hidden="true"
      >
        <img
          className="max-w-none"
          src="/images/blurred-shape-gray.svg" 
          width={760}
          height={668}
          alt="Blurred shape"
        />
      </div>
      <div
        className="pointer-events-none absolute bottom-0 left-1/2 -z-10 -mb-80 -translate-x-[120%] opacity-50"
        aria-hidden="true"
      >
        <img
          className="max-w-none"
          src="/images/blurred-shape.svg"
          width={760}
          height={668}
          alt="Blurred shape"
        />
      </div>

        <div className="border-t py-10 mb-10 sm:pb-10 [border-image:linear-gradient(to_right,transparent,theme(colors.slate.400/.25),transparent)1] md:py-20">
        <div className="mx-auto px-4 sm:px-6 max-w-6xl text-center">
          <div className="inline-flex md:pb-20 pb-10 items-center gap-3  before:h-px before:w-8 before:bg-gradient-to-r before:from-transparent before:to-indigo-200/50 after:h-px after:w-8 after:bg-gradient-to-l after:from-transparent after:to-indigo-200/50">
          <span className="inline-flex bg-gradient-to-r from-indigo-500 to-indigo-200 bg-clip-text text-transparent text-2xl">
                About Us
              </span>
            </div>
            <div className="flex flex-col md:flex-row items-center md:items-start justify-between mx-0 w-full">
              <div className="md:w-1/2 ml-2.5 md:ml-0 mr-[40px] text-left mt-4">
                <h4 className="animate-[gradient_6s_linear_infinite] bg-[linear-gradient(to_right,theme(colors.gray.200),theme(colors.indigo.200),theme(colors.gray.50),theme(colors.indigo.300),theme(colors.gray.200))] bg-[length:200%_auto] bg-clip-text pb-4 font-nacelle text-3xl font-semibold text-transparent md:text-3xl">
                  What We Do
                </h4>
                <p className="text-lg text-indigo-200/65" data-aos="fade-right" data-aos-duration="1000">
                At HyGaar, we empower enterprises and large businesses to create and deliver personalized, immersive video content directly to their customers using our proprietary, AI-driven tools and algorithms. By harnessing the power of video and artificial intelligence, we help businesses forge deeper, more personal connections with their customers.                </p>
              </div>

              <div className="md:w-1/2  w-1/4 flex justify-center pt-4 sm:pt-0 md:pb-12 sm:mx-0" data-aos="fade-left" data-aos-duration="1000">
                <video
                  className="sm:max-w-none max-w-[360px] rounded-xl"
                  width={470}
                  height={150}
                  loop
                  autoPlay
                  muted
                  alt="Features"
                >
                  <source src={`${process.env.PUBLIC_URL}/images/Aboutus.mp4`} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          </div>
        </div>
    </section>
  );
}
