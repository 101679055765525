import { BrowserRouter, Route, Routes } from "react-router-dom";
import React from "react";
import RootLayout from "./layout";
import Home from "./Home";
import "./css/style.css";

function App() {
  return (
    <BrowserRouter>
      <RootLayout>
        <Routes>
          <Route path="/" element={<Home />} />
        </Routes>
      </RootLayout>
    </BrowserRouter>

  );
}
export default App;
