import React, { useState } from "react";
import { FaMapMarkerAlt } from 'react-icons/fa';

export default function ContactUs() {
  const [formData, setFormData] = useState({
    name: "",
    company: "",
    email: "",
    designation: "",
    phone_number: "",
  });

  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const apiUrl = process.env.REACT_APP_API_URL;


  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setSuccessMessage("");
    setErrorMessage("");

    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Something went wrong');
      }

      const contentType = response.headers.get("content-type");
      if (contentType && contentType.includes("application/json")) {
        const data = await response.json();
        console.log("Form Data Submitted: ", data);
        setFormData({
          name: "",
          company: "",
          email: "",
          designation: "",
          phone_number: "",
        });
        setSuccessMessage(data.message || "Form submitted successfully!");
      } else {
        throw new Error("Unexpected response format");
      }
    } catch (error) {
      console.error("Error submitting form: ", error);
      setErrorMessage(error.message);
    } finally {
      setLoading(false);
    }
  };
  const closeModal = () => {
    setSuccessMessage("");
    setErrorMessage("");
  };

  return (
    <section>
      <div className="mx-auto max-w-6xl px-4 sm:px-6">
        <div className="py-12 md:py-20">
          <div className="mx-auto px-4 sm:px-6 max-w-6xl text-center">

            <div className="inline-flex md:pb-2 pb-2 items-center gap-3 pb-3 before:h-px before:w-8 before:bg-gradient-to-r before:from-transparent before:to-indigo-200/50 after:h-px after:w-8 after:bg-gradient-to-l after:from-transparent after:to-indigo-200/50">
              <span className="inline-flex bg-gradient-to-r from-indigo-500 to-indigo-200 bg-clip-text text-transparent text-2xl">
                Contact Us
              </span>
            </div>
          </div>
          <div className="grid gap-8 md:grid-cols-2 items-start">

            <div className="md:pr-8">


              <form onSubmit={handleSubmit} className="mt-8 space-y-6" data-aos="fade-right" data-aos-delay={200}>
                <div className="flex flex-col">
                  <label className="mb-2 font-semibold text-gray-300">Name</label>
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    className="rounded-md border border-gray-600 bg-gray-800/50 p-3 text-white"
                    placeholder="Enter your name"
                    required
                  />
                </div>

                <div className="flex flex-col">
                  <label className="mb-2 font-semibold text-gray-300">Company</label>
                  <input
                    type="text"
                    name="company"
                    value={formData.company}
                    onChange={handleChange}
                    className="rounded-md border border-gray-600 bg-gray-800/50 p-3 text-white"
                    placeholder="Enter your company name"
                    required
                  />
                </div>

                <div className="flex flex-col">
                  <label className="mb-2 font-semibold text-gray-300">Email</label>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className="rounded-md border border-gray-600 bg-gray-800/50 p-3 text-white"
                    placeholder="Enter your email"
                    required
                  />
                </div>

                <div className="flex flex-col">
                  <label className="mb-2 font-semibold text-gray-300">Designation</label>
                  <input
                    type="text"
                    name="designation"
                    value={formData.designation}
                    onChange={handleChange}
                    className="rounded-md border border-gray-600 bg-gray-800/50 p-3 text-white"
                    placeholder="Enter your designation"
                    required
                  />
                </div>

                <div className="flex flex-col">
                  <label className="mb-2 font-semibold text-gray-300">Phone No.</label>
                  <input
                    type="tel"
                    name="phone_number"
                    value={formData.phone_number}
                    onChange={handleChange}
                    className="rounded-md border border-gray-600 bg-gray-800/50 p-3 text-white"
                    placeholder="Enter your phone number"
                    required
                  />
                </div>

                <button
                  type="submit"
                  className={`w-full rounded-md ${loading ? 'bg-gray-500' : 'bg-gradient-to-r from-indigo-600 to-indigo-400'} px-4 py-3 text-white transition-all duration-300 hover:opacity-90`}
                  disabled={loading}
                >
                  {loading ? 'Submitting...' : 'Submit'}
                </button>


              </form>
            </div>

            <div className="flex justify-center md:justify-end">
              <div
                className="relative w-full max-w-lg flex flex-col mt-20 justify-between h-full"
                data-aos="fade-left"
                data-aos-delay={300}
              >


                <div className="bg-gray-800/50 p-6 rounded-xl border border-gray-600 shadow-lg">
                  <h3 className="text-xl font-semibold text-gray-300 flex items-center">
                    <FaMapMarkerAlt className="mr-2 text-indigo-400" /> Our Location
                  </h3>
                  <p className="mt-2 text-gray-200">
                    1st Floor, Oberoi Commerz II Building,<br />
                    Oberoi Garden City, Mohan Gokhale Road,<br />
                    Off Western Express Highway,<br />
                    Goregaon(E), Mumbai- 400063<br />
                    India
                  </p>
                  <div className="mt-4">
                    <a href="https://www.google.com/maps?q=1st+Floor,+Oberoi+Commerz+II+Building,+Oberoi+Garden+City,+Mohan+Gokhale+Road,+Off+Western+Express+Highway,+Goregaon(E),+Mumbai-+400063,+India" target="_blank" rel="noopener noreferrer" className="text-indigo-400 hover:underline">
                      View on Google Maps
                    </a>
                  </div>
                </div>
                <div className="mt-6 text-center md:text-left p-1">
                  <p className="mt-4 text-xl text-gray-200 leading-relaxed  ">
                    We're here to help you unlock exciting opportunities! <br />
                    <span className="font-bold text-indigo-400">
                      Connect with us, and open doors to new possibilities.                    </span>
                    <span className="block mt-2 text-gray-300">
                      Your thoughts matter to us, and our team is ready to assist you with whatever you need.                     </span>
                  </p>

                  <p className="mt-4 text-xl text-gray-200 leading-relaxed">
                    <span className="italic text-indigo-300">Got a question?</span>
                    <span className="font-semibold"> We're all ears and eager to help!</span>

                  </p>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      {(successMessage || errorMessage) && (
        <div className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-25">
          <div className="bg-gray-900 p-8 rounded-xl shadow-lg w-auto relative">
            <button
              onClick={closeModal}
              className="absolute top-1 right-2 text-white text-xl"
            >
              &times;
            </button>
            <p className="mt-2 text-white">{successMessage || errorMessage}</p>
          </div>
        </div>
      )}
    </section>
  );
}
