import React, { useEffect } from "react";
import "./css/style.css";
import AOS from "aos";
import "aos/dist/aos.css";
import Header from "./components/ui/Header";
import Footer from "./components/ui/Footer";


const RootLayout = ({ children }) => {
  useEffect(() => {
    AOS.init({
      once: true,
      disable: "phone",
      duration: 600,
      easing: "ease-out-sine",
    });
  }, []);

  return (
    <div className="flex min-h-screen flex-col overflow-hidden supports-[overflow:clip]:overflow-clip bg-gray-950 text-base text-gray-200 antialiased">
      <Header />
      <main className="relative flex grow flex-col">{children}</main>
      <Footer />
    </div>
  );
};

export default RootLayout;
