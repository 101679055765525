import { useState } from "react";
import Spotlight from "./Spotlight";

import Modal from "./Modal";

const cardData = [
  {
    image: "/images/BrowsingHistory.png",
    title: "Video from Browsing History",
    video: `${process.env.PUBLIC_URL}/images/BrowsingHistory.mp4`,
    content: "Engage your visitors by focusing on what matters most to them.Our Browser History Video tool lets you access each user's unique browsing history through our APIs, enabling you to create customized videos that showcase the products and content they find most relevant."
  },
  {
    image: "/images/AbandonedCart.png",
    title: "Videos from Abandoned Cart",
    video: `${process.env.PUBLIC_URL}/images/AbandonedCart.mp4`,
    content: "Boost your sales with our intelligent abandoned cart engagement tool. It creates personalized videos showcasing the exact items users left in their carts, motivating them to return and complete their purchases. By analyzing their behavior, the system also recommends similar products through engaging videos. Over time, it learns the urgency and timing of purchases to deliver the most engaging and profitable outcomes for your business."

  },
  {
    image: "/images/EmailContent.png",
    title: "Email to Video",
    video: `${process.env.PUBLIC_URL}/images/EmailtoVideo.mp4`,

    content: "Elevate your email communications with our Email to Video tool. Effortlessly transform your basic HTML emails into immersive and engaging videos using our AI-powered dashboard. Simply upload your email template or HTML file, or integrate with our APIs, and our system will generate dynamic videos from your email's content and images. Capture your audience's attention and enhance engagement like never before."

  },
];

export default function Workflows() {
  const [isOpen, setIsOpen] = useState(false);
  const [currentCard, setCurrentCard] = useState({ title: "", content: "", image: "/images/workflow-01.png" });
  const [hoveredIndex, setHoveredIndex] = useState(null);


  const handleOpenModal = (title, content, image, video) => {
    setCurrentCard({ title, content, image, video });
    setIsOpen(true);
  };


  const handleCloseModal = () => {
    setIsOpen(false);
  };

  return (
    <section>
      <div className="mx-auto max-w-6xl px-4 sm:px-6">
        <div className="pb-3 md:pb-6">

          <div className="mx-auto max-w-3xl pb-6 text-center md:pb-15">
            <div className="inline-flex items-center gap-3 pb-3 before:h-px before:w-8 before:bg-gradient-to-r before:from-transparent before:to-indigo-200/50 after:h-px after:w-8 after:bg-gradient-to-l after:from-transparent after:to-indigo-200/50">
              <span className="inline-flex bg-gradient-to-r from-indigo-500 to-indigo-200 bg-clip-text text-transparent text-2xl">
                Our Products
              </span>
            </div>

          </div>

          <div data-aos="fade-up" data-aos-duration="500"
            data-aos-delay="300"
          >
            <Spotlight className="group mx-auto grid max-w-sm items-start gap-6 lg:max-w-none lg:grid-cols-3 ">
              {cardData.map((card, index) => (
                <a
                  key={index}
                  className={`group/card relative h-full overflow-hidden rounded-2xl bg-gray-800 p-px transition-all duration-300 ${hoveredIndex === index ? 'ring-2 ring-indigo-500' : ''
                    }`}
                  href="#0"
                  onMouseEnter={() => setHoveredIndex(index)}
                  onMouseLeave={() => setHoveredIndex(null)}
                  onClick={(e) => {
                    e.preventDefault();
                    handleOpenModal(card.title, card.content, card.image, card.video);
                  }}

                  style={{
                    transform: hoveredIndex === index ? 'scale(1)' : 'scale(0.95)',
                    opacity: hoveredIndex === index ? 1 : 0.8,
                    transition: 'transform 0.9s ease-out, opacity 0.3s ease-out',
                    zIndex: hoveredIndex === index ? 2 : 1
                  }}

                >
                  <div className="relative z-20 h-full overflow-hidden rounded-[inherit] bg-gray-950"
                  >
                    <div
                      className="absolute right-6 top-6 flex h-8 w-8 items-center justify-center rounded-full border border-gray-700/50 bg-gray-800/65 text-gray-200 opacity-100 transition-opacity duration-300 cursor-pointer"
                      aria-hidden="true"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" width={9} height={8} fill="none">
                        <path fill="#F4F4F5" d="m4.92 8-.787-.763 2.733-2.68H0V3.443h6.866L4.133.767 4.92 0 9 4 4.92 8Z" />
                      </svg>
                    </div>




                    {/* Content */}
                    <div className="p-6">

                      <div className="mb-3">
                        <span className="btn-sm relative rounded-full bg-gray-800/40 px-2.5 py-0.5 text-xs font-normal hover:bg-gray-800/60 ">
                          <span className="bg-gradient-to-r from-indigo-500 to-indigo-200 bg-clip-text text-transparent text-[18px]">
                            {card.title}

                          </span>

                        </span>
                        {/* <p className="text-indigo-200/65 text-xs px-2.5 py-0.5">
                          {"Curious to see how it works? Click to explore!"}
                        </p> */}
                      </div>
                      <img
                        className="inline-flex w-full h-[350px] object-cover"
                        src={card.image}
                        alt={`Workflow ${index + 1}`}
                      />

                      <p className="text-indigo-200/65">
                        {card.content.split(' ').slice(0, 15).join(' ') + '...'}
                      </p>

                    </div>
                  </div>
                </a>
              ))}
            </Spotlight>
          </div>
        </div>
      </div>


      <Modal
        isOpen={isOpen}
        onClose={handleCloseModal}
        title={currentCard.title}
        content={currentCard.content}
        image={currentCard.image}
        video={currentCard.video}
      />

    </section>
  );
}
